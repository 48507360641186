import React, { Fragment, useState } from "react"
import { navigate } from "gatsby"
import classNames from "classnames"

import Button from "./Button"
import TurnstileCaptcha from "./TurnstileCaptcha"

const ResponsiveActionButtons = ({ isMobile, submit, next, back, checkCaptcha, hasCaptcha }) => {

  return (
    <Fragment>
      {back && (
        <Button
          onClick={() => {
            if (back.callback) {
              back.callback()
              return
            }
            navigate(back.link)
          }}
          className={classNames("px-2 px-2-mobile mr-1 mr-1-mobile", {
            "is-hidden-desktop is-hidden-tablet": !!isMobile,
            "is-hidden-mobile": !isMobile,
          })}
          size={isMobile ? "regular" : "medium"}
        >
          {back.label}
        </Button>
      )}  

      {next && (
        <Button
          onClick={() => {
            if (next.callback) {
              next.callback()
              return
            }
          }}
          className={classNames("px-2 px-2-mobile mr-1 mr-1-mobile", {
            "is-hidden-desktop is-hidden-tablet": !!isMobile,
            "is-hidden-mobile": !isMobile,
          })}
          size={isMobile ? "regular" : "medium"}
          color="primary"
          isDisabled={next.disabled}
        >
          {next.label}
        </Button>
      )}

      {submit && (
        <Button
          className={classNames("px-2 px-2-mobile mr-1 mr-1-mobile", {
            "is-hidden-desktop is-hidden-tablet": !!isMobile,
            "is-hidden-mobile": !isMobile,
          })}
          size={isMobile ? "regular" : "medium"}
          color="primary"
          type="submit"
          isLoading={submit.loading}
          isDisabled={submit.disabled || (hasCaptcha && !checkCaptcha)}
          onClick={() => navigate(submit.link)} 
        >
          {submit.label}
        </Button>
      )}
    </Fragment>
  )
}

const ActionButtons = ({ submit, next, back, hasCaptcha }) => {
  const [checkCaptcha, setCheckCaptcha] = useState(false)

  return (
    <Fragment>
      <div class="mb-1 is-flex is-justify-content-center">
            {hasCaptcha && (
              <TurnstileCaptcha setCheckCaptcha={setCheckCaptcha} onSuccess={() => setCheckCaptcha(true)}/>
            )}
      </div>
      <div className="buttons is-centered">
        <ResponsiveActionButtons submit={submit} next={next} back={back} checkCaptcha={checkCaptcha} hasCaptcha={hasCaptcha}/>
        <ResponsiveActionButtons isMobile submit={submit} next={next} back={back} checkCaptcha={checkCaptcha} hasCaptcha={hasCaptcha}/>
      </div>
    </Fragment>
  )
}

export default ActionButtons
